const en = {
  appName: 'Act',
  navBar: {
    vision: 'Vision',
  },
  home: {
    title: 'Home',
    subtitle: `Your App for Free and Impartial News`,
    aboutUs1: "ACT redefines the media landscape with a boldness that bewilders, an application where news is no longer a monologue but a vibrant dialogue. Imagine a world where every piece of information is the starting point for a collective exploration, where every reader becomes a key player in the quest for truth. At ACT, we're not just changing the rules of the game; we're inventing a new game where impartiality and civic engagement redraw the contours of information.",
    aboutUs2: "Our mission is clear. To build an information ecosystem governed by integrity, authenticity, and an unending quest for diversity in perspectives. We offer a social media where the quality of information prevails, where every voice can resonate with strength and clarity, thus shaping the media landscape of tomorrow.",
    aboutUs3: "The process is simple yet powerful: each Act published initiates a wave of reactions. Users, armed with their discernment and expertise, contribute through Reacts that do more than just react; they evaluate, analyze, and enrich the debate. This cycle of Acts and Reacts creates a living, constantly evolving dynamic.",
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    description: `This Privacy Policy governs the collection, use, and protection of data of users of the Act application ("the Application") developed by SAVEPNP ("we", "our", "us", "the company"). We attach great importance to the privacy of users of our application and are committed to protecting their data in accordance with applicable laws and regulations regarding data privacy.`,
    collectData: {
      title: `Data Collection`,
      content: `We may collect certain personally identifiable information (PII) from users, including but not limited to: name, email address, phone number, mailing address, and other similar information, when voluntarily submitted by users in the course of using the Application. We may also automatically collect certain non-personal information, such as usage data, login information, geolocation data, and other similar information, when users interact with the Application.`
    },
    useData: {
      title: `Data Use`,
      content: `We use the collected data to provide, maintain, improve, and personalize the user experience with the Application. The data may also be used to communicate with users, respond to their requests, provide customer service, send information about updates to the Application, and for other legitimate purposes related to the operation of the Application.`
    },
    protectData: {
      title: `Data Protection`,
      content: `We implement appropriate security measures to protect user data against unauthorized access, disclosure, alteration, or unauthorized destruction of data. However, it should be noted that no method of data transmission over the Internet, or method of electronic storage, is completely secure. Therefore, we cannot guarantee the absolute security of data transmitted or stored via the Application.`
    },
    shareData: {
      title: `Data Sharing`,
      content: `We do not sell, trade, or rent user data to third parties, except as required by law or with explicit consent from users. We may share user data with third-party service providers who assist us in operating and improving the Application, provided they also comply with data privacy confidentiality.`
    },
    cookie: {
      title: `Cookies and Similar Technologies`,
      content: `The Application may use cookies, web beacons, pixel tags, and other similar technologies to automatically collect certain non-personal information, such as usage data, to improve the user experience, personalize content and ads, and for other legitimate purposes.`
    },
    links: {
      title: `Links to Third-Party Websites`,
      content: `The Application may contain links to third-party websites. We have no control over the content or privacy practices of these websites and are not responsible for their privacy practices. We encourage users to review the privacy policies of these third-party websites before submitting personal information.`
    },
    majPrivacyPolicy: {
      title: `Updates to the Privacy Policy`,
      content: `We reserve the right to modify this Privacy Policy at any time by publishing an updated version at this link https://goact.ai/privacy-policy. The changes will be effective upon publication on the Application. It is the responsibility of users to regularly review this Privacy Policy to be informed of any changes. Continued use of the Application after the publication of changes constitutes acceptance of these changes.`
    },
    consent: {
      title: `Consent`,
      content: `By using the Application, users consent to the collection, use, and protection of their data in accordance with this Privacy Policy.`
    },
    rightUser: {
      title: `User Rights`,
      content: `Users have the right to access their personal data, rectify, delete, object to their processing, or request their portability, in accordance with applicable laws and regulations regarding data privacy. Users can exercise these rights by contacting us.`
    },
    contact: {
      title: `Contact`,
      content: `If you have any questions, concerns, or comments about this Privacy Policy or our data privacy practices, please contact us.`
    },
    conclusion: {
      title: `Conclusion`,
      content: `We attach great importance to the privacy protection of users of our application. We are committed to treating user data confidentially and taking appropriate measures to ensure their security. We encourage you to regularly review this Privacy Policy to stay informed of the privacy practices of our application.`
    },
    startDate: `Effective Date : 04/24/2023`,
  },
  terms: {
    title: `Terms and Conditions of Use`,
    scopeOfConditions: {
      title: `Scope and Conditions of Use`,
      content1: `The use of this application is subject to the unconditional acceptance of these Terms and Conditions of Use.`,
      content2: `The user must comply with applicable laws and regulations when using the application.`,
      content3: `The user may not use the application for illegal, fraudulent, harmful, or offensive purposes.`
    },
    intellectualProperty: {
      title: `Intellectual Property`,
      content1: `All intellectual property rights related to the application, including copyrights, trademarks, patents, and trade secrets, are the property of SAVEPNP.`,
      content2: `The user is not authorized to copy, reproduce, distribute, modify, or create derivative works of the application without the prior written permission of SAVEPNP.`,
    },
    privacyAndDataSecurity: {
      title: `Confidentiality and Data Security`,
      content1: `SAVEPNP collects, uses, and protects user data in accordance with its privacy policy available on the application.`,
      content2: `SAVEPNP implements reasonable security measures to protect user data, but cannot guarantee the absolute security of data transmitted through the application.`,
    },
    limitationOfLiability: {
      title: `Limitation of Liability`,
      content1: `The use of the application is at the user's own risk. SAVEPNP cannot be held responsible for losses, damages, disputes, or damages arising from the use of the application.`,
      content2: `SAVEPNP does not guarantee that the application will be free from errors, interruptions, viruses, or other harmful components.`,
    },
    abusiveContent: {
      title: `SAVEPNP has a zero tolerance policy for objectionable content and abusive users.`,
      content1: `Objectionable content includes, but is not limited to: hate speech, threats, harassment, child pornography, incitement to violence, etc.`,
      content2: `Users must refrain from any abusive behavior towards other users of the application.`,
      content3: `SAVEPNP reserves the right to take action, such as content removal, account deactivation, or sharing information with relevant authorities, to address cases of objectionable content or abusive users.`,
      content4: `SAVEPNP is committed to addressing all reported content within a maximum timeframe of 24 hours from the time of receiving the report.`,
      content5: `Users are encouraged to report any objectionable content or abusive users so that SAVEPNP can take appropriate measures promptly.`,
      content6: `Users are encouraged to report any objectionable content or user abuse so that SAVEPNP can take appropriate action as soon as possible.`,
    },
    conflictResolution: {
      title: `Dispute Resolution Procedures`,
      content1: `Any dispute arising from the use of the application will be subject to the exclusive jurisdiction of competent courts.`,
      content2: `The parties agree to attempt to resolve any dispute amicably before resorting to judicial procedures.`,
    },
    updatesCgu: {
      title: `Updates to the Terms and Conditions of Use`,
      content1: `SAVEPNP reserves the right to update these Terms and Conditions of Use at any time by publishing an updated version at this link https://goact.ai/tos.`,
      content2: `Users will be informed of updates to the Terms and Conditions of Use and will be required to accept them to continue using the application.`,
    },
  },
  downloadApp: {
    title: `Join the Movement !`,
    subtitle: `Download the App to Dive into Authentic and Unbiased News`,
  },
  footer: {
    followUs: 'Dive into our world ! Follow us on social media for content that inspires and entertains !',
    contactUs: 'Contact Us',
    byAct: 'By Act',
  }
};

export default en;
