<template>
  <div>
    <div id="hero-area" class="home_five-hero-section mn-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 px-xxl-6">
            <div class="hero-content text-center">
              <h1
                class="fs-50 mb-50"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                {{ $t("appName") }}
              </h1>

              <h2>{{ $t("home.subtitle") }}</h2>

              <ol class="about-us text-left">
                <li>
                  {{ $t("home.aboutUs1") }}
                </li>
                <li>
                  {{ $t("home.aboutUs2") }}
                </li>
                <li>
                  {{ $t("home.aboutUs3") }}
                </li>
              </ol>

              <!-- <div
                class="hero-btn d-flex align-items-center justify-content-center"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div
                  class="play-now d-inline-flex align-items-center"
                  v-on:click="isPopupMethod(isPopup)"
                  style="cursor: pointer"
                >
                  <span
                    class="play-btn d-flex flex-column justify-content-center align-items-center rounded-circle transition"
                  >
                    <i class="ri-play-mini-fill"></i>
                  </span>
                  <span class="play-text transition sm-none">
                    App Presentation
                  </span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-video" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe src="https://www.youtube.com/embed/3FjT7etqxt8"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainBanner",
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup: unknown) {
      return (this.isPopup = !isPopup);
    },
  },
});
</script>

<style lang="scss" scoped>
.home_five-hero-section {
  // background-image: url(../../assets/img/home-five/hero/hero-bg.webp);
  padding: 225px 0 115px;
  .hero-content {
    .about-us {
      margin-top: 50px;
      li {
        text-align: left;
        font-size: 18px;
        line-height: 32px;
        color: #0b0619;
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
      }
    }
    .hero-btn {
      .play-now {
        margin-left: 25px;
        span {
          &.play-btn {
            width: 51px;
            height: 51px;
            border: 1px solid #0b0619;
            margin-right: 15px;

            i {
              font-size: 33px;
              color: #0b0619;
              transition: var(--transition);
              position: relative;
              left: 2px;
              top: 1px;
            }
          }
          &.play-text {
            font-size: 33px;
            color: #0b0619;
          }
        }
        &:hover {
          span {
            &.play-btn {
              border-color: transparent;
              background-color: var(--primaryColor);
              i {
                color: var(--whiteColor);
              }
            }
            &.play-text {
              color: var(--primaryColor);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_five-hero-section {
    padding: 120px 0 75px;
    .hero-content {
      h1 {
        font-size: 34px;
        line-height: 48px;
      }
      .hero-btn {
        a {
          &.btn {
            padding: 19px 18px 19px;
          }
          &.play-now {
            margin-left: 12px;
            span {
              &.play-btn {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .home_five-hero-section {
    .hero-content {
      margin-bottom: 30px;
    }
  }
}
</style>