import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarTwo = _resolveComponent("NavbarTwo")!
  const _component_PageBanner = _resolveComponent("PageBanner")!
  const _component_PrivacyPolicy = _resolveComponent("PrivacyPolicy")!
  const _component_DownloadTheApp = _resolveComponent("DownloadTheApp")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NavbarTwo, { class: "home_five" }),
    _createVNode(_component_PageBanner, {
      pageTitle: _ctx.$t(`privacyPolicy.title`),
      mainTitle: _ctx.$t(`privacyPolicy.title`)
    }, null, 8, ["pageTitle", "mainTitle"]),
    _createVNode(_component_PrivacyPolicy),
    _createVNode(_component_DownloadTheApp),
    _createVNode(_component_MainFooter)
  ]))
}