<template>
  <div>
    <NavbarTwo class="home_five" />
    <!-- <ResponsiveNavbarTwo class="home_five" /> -->
    <MainBanner />
    <DownloadTheApp />
    <MainFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavbarTwo from "../components/Layouts/NavbarTwo.vue";
import ResponsiveNavbarTwo from "../components/Layouts/ResponsiveNavbarTwo.vue";
import MainBanner from "../components/Home/MainBanner.vue";
import DownloadTheApp from "../components/Common/DownloadTheApp.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "Home",
  components: {
    NavbarTwo,
    ResponsiveNavbarTwo,
    MainBanner,
    DownloadTheApp,
    MainFooter
  },
});
</script>