<template>
  <div>
    <PreLoader />
    <!-- <ThemeSwitcher /> -->
    <!-- <DemoPopup /> -->
    <router-view />
    <BackToTop />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PreLoader from "./components/Layouts/PreLoader.vue";
// import ThemeSwitcher from "./components/Layouts/ThemeSwitcher.vue";
// import DemoPopup from "./components/Layouts/DemoPopup.vue";
import BackToTop from "./components/Layouts/BackToTop.vue";

export default defineComponent({
  name: "App",
  components: {
    PreLoader,
    // ThemeSwitcher,
    // DemoPopup,
    BackToTop,
  },
});
</script>