<template>
  <div>
    <NavbarTwo class="home_one" />
    <PageBanner :pageTitle="$t(`terms.title`)" :mainTitle="$t(`terms.title`)" />
    <TermsConditions />
    <DownloadTheApp />
    <MainFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavbarTwo from "../components/Layouts/NavbarTwo.vue";
import PageBanner from "../components/Common/PageBanner.vue";
import TermsConditions from "../components/TermsConditions/TermsConditions.vue";
import DownloadTheApp from "../components/Common/DownloadTheApp.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "TermsConditionsPage",
  components: {
    NavbarTwo,
    PageBanner,
    TermsConditions,
    DownloadTheApp,
    MainFooter,
  },
});
</script>