<template>
  <div class="breadcrumb-wrap index-1">
    <div class="container text-center index-1">
      <h2 class="br-title fs-52 mb-15">{{ pageTitle }}</h2>
      <ul class="br-menu list-style">
        <li>
          <router-link to="/">{{$t('home.title')}}</router-link>
        </li>
        <li>{{ mainTitle }}</li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageBanner",
  props: ["pageTitle", "mainTitle"],
});
</script>

<style lang="scss" scoped>
.breadcrumb-wrap {
  .container {
    padding: {
      top: 180px;
      bottom: 40px;
    }
    .br-shape-one {
      top: 33%;
      left: 2%;
    }
    .br-shape-two {
      bottom: 18%;
      right: 0%;
    }
  }
  .br-menu {
    padding-left: 24px;
    position: relative;
    display: inline-block;
    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      content: "";
      width: 19px;
      height: 19px;
      background-image: url(../../assets/img/icons/home.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 19px;
    }
    li {
      display: inline-block;
      position: relative;
      color: #565067;
      padding-right: 14px;
      margin-right: 4px;
      &:after {
        position: absolute;
        top: 0px;
        right: 0px;
        content: "/";
        font-size: 15px;
        color: #565067;
      }
      &:first-child {
        margin-left: 0;
        a {
          padding-left: 0;
        }
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        font-weight: 300;
        &:after {
          display: none;
        }
      }
      a {
        display: inline-block;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        position: relative;
        color: var(--optionalColor);
        &:hover {
          color: var(--secondaryColor);
        }
      }
      &:last-child {
        a {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-wrap {
    .container {
      padding: {
        top: 150px;
        bottom: 50px;
      }
    }
    .br-menu {
      padding-left: 24px;
      li {
        font-size: 14px;
        &:after {
          font-size: 12px;
          right: -1px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-wrap {
    .container {
      padding: {
        top: 150px;
        bottom: 80px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-wrap {
    .container {
      padding: {
        top: 220px;
        bottom: 90px;
      }
    }
  }
}
</style>