<template>
  <div class="terms-wrap ptb-100">
    <div class="container">
      <div class="row gx-5">
        <div class="col-lg-12">
          <div class="single-para">
            <p>
              {{$t(`privacyPolicy.description`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.collectData.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.collectData.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.useData.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.useData.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.protectData.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.protectData.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.shareData.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.shareData.content`)}}
            </p>
          </div>

          <div class="single-para">
            <h3>{{$t(`privacyPolicy.cookie.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.cookie.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.links.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.links.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.majPrivacyPolicy.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.majPrivacyPolicy.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.consent.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.consent.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.rightUser.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.rightUser.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.contact.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.contact.content`)}}
            </p>
          </div>
          <div class="single-para">
            <h3>{{$t(`privacyPolicy.conclusion.title`)}}</h3>
            <p>
              {{$t(`privacyPolicy.conclusion.content`)}}
            </p>
          </div>
          <p>
            {{$t(`privacyPolicy.startDate`)}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};

</script>