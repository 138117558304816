import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import HomeOnePage from "../pages/HomeOnePage.vue";
import HomeTwoPage from "../pages/HomeTwoPage.vue";
import HomeThreePage from "../pages/HomeThreePage.vue";
import HomeFourPage from "../pages/HomeFourPage.vue";
import Home from "../pages/Home.vue";
import Vision from "../pages/Vision.vue";
import HomeSixPage from "../pages/HomeSixPage.vue";
import HomeSevenPage from "../pages/HomeSevenPage.vue";
import HomeEightPage from "../pages/HomeEightPage.vue";
import AboutUsPage from "../pages/AboutUsPage.vue";
import PricingPage from "../pages/PricingPage.vue";
import FAQPage from "../pages/FAQPage.vue";
import ContactUsPage from "../pages/ContactUsPage.vue";
import PortfolioPage from "../pages/PortfolioPage.vue";
import PortfolioDetailsPage from "../pages/PortfolioDetailsPage.vue";
import ShopLeftSidebarPage from "../pages/ShopLeftSidebarPage.vue";
import ShopRightSidebarPage from "../pages/ShopRightSidebarPage.vue";
import ShopPage from "../pages/ShopPage.vue";
import ProductDetailsPage from "../pages/ProductDetailsPage.vue";
import CartPage from "../pages/CartPage.vue";
import WishlistPage from "../pages/WishlistPage.vue";
import CheckoutPage from "../pages/CheckoutPage.vue";
import BlogLeftSidebarPage from "../pages/BlogLeftSidebarPage.vue";
import BlogRightSidebarPage from "../pages/BlogRightSidebarPage.vue";
import BlogPage from "../pages/BlogPage.vue";
import PostsByCategoryPage from "../pages/PostsByCategoryPage.vue";
import PostsByTagPage from "../pages/PostsByTagPage.vue";
import PostsByAuthorPage from "../pages/PostsByAuthorPage.vue";
import BlogDetailsLeftSidebarPage from "../pages/BlogDetailsLeftSidebarPage.vue";
import BlogDetailsRightSidebarPage from "../pages/BlogDetailsRightSidebarPage.vue";
import BlogDetailsPage from "../pages/BlogDetailsPage.vue";
import LoginPage from "../pages/LoginPage.vue";
import RegisterPage from "../pages/RegisterPage.vue";
import TermsConditionsPage from "../pages/TermsConditionsPage.vue";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/vision",
    name: "Vision",
    component: Vision,
  },
  // {
  //   path: "/home-two",
  //   name: "HomeTwoPage",
  //   component: HomeTwoPage,
  // },
  // {
  //   path: "/home-three",
  //   name: "HomeThreePage",
  //   component: HomeThreePage,
  // },
  // {
  //   path: "/home-four",
  //   name: "HomeFourPage",
  //   component: HomeFourPage,
  // },
  // {
  //   path: "/home-five",
  //   name: "HomeFivePage",
  //   component: HomeFivePage,
  // },
  // {
  //   path: "/home-six",
  //   name: "HomeSixPage",
  //   component: HomeSixPage,
  // },
  // {
  //   path: "/home-seven",
  //   name: "HomeSevenPage",
  //   component: HomeSevenPage,
  // },
  // {
  //   path: "/home-eight",
  //   name: "HomeEightPage",
  //   component: HomeEightPage,
  // },
  // {
  //   path: "/about-us",
  //   name: "AboutUsPage",
  //   component: AboutUsPage,
  // },
  // {
  //   path: "/pricing-plan",
  //   name: "PricingPage",
  //   component: PricingPage,
  // },
  // {
  //   path: "/faq",
  //   name: "FAQPage",
  //   component: FAQPage,
  // },
  // {
  //   path: "/contact-us",
  //   name: "ContactUsPage",
  //   component: ContactUsPage,
  // },
  // {
  //   path: "/portfolio",
  //   name: "PortfolioPage",
  //   component: PortfolioPage,
  // },
  // {
  //   path: "/portfolio-details",
  //   name: "PortfolioDetailsPage",
  //   component: PortfolioDetailsPage,
  // },
  // {
  //   path: "/shop-left-sidebar",
  //   name: "ShopLeftSidebarPage",
  //   component: ShopLeftSidebarPage,
  // },
  // {
  //   path: "/shop-right-sidebar",
  //   name: "ShopRightSidebarPage",
  //   component: ShopRightSidebarPage,
  // },
  // {
  //   path: "/shop",
  //   name: "ShopPage",
  //   component: ShopPage,
  // },
  // {
  //   path: "/product-details",
  //   name: "ProductDetailsPage",
  //   component: ProductDetailsPage,
  // },
  // {
  //   path: "/cart",
  //   name: "CartPage",
  //   component: CartPage,
  // },
  // {
  //   path: "/wishlist",
  //   name: "WishlistPage",
  //   component: WishlistPage,
  // },
  // {
  //   path: "/checkout",
  //   name: "CheckoutPage",
  //   component: CheckoutPage,
  // },
  // {
  //   path: "/blog-left-sidebar",
  //   name: "BlogLeftSidebarPage",
  //   component: BlogLeftSidebarPage,
  // },
  // {
  //   path: "/blog-right-sidebar",
  //   name: "BlogRightSidebarPage",
  //   component: BlogRightSidebarPage,
  // },
  // {
  //   path: "/blog",
  //   name: "BlogPage",
  //   component: BlogPage,
  // },
  // {
  //   path: "/posts-by-category",
  //   name: "PostsByCategoryPage",
  //   component: PostsByCategoryPage,
  // },
  // {
  //   path: "/posts-by-tag",
  //   name: "PostsByTagPage",
  //   component: PostsByTagPage,
  // },
  // {
  //   path: "/posts-by-author",
  //   name: "PostsByAuthorPage",
  //   component: PostsByAuthorPage,
  // },
  // {
  //   path: "/blog-details-left-sidebar",
  //   name: "BlogDetailsLeftSidebarPage",
  //   component: BlogDetailsLeftSidebarPage,
  // },
  // {
  //   path: "/blog-details-right-sidebar",
  //   name: "BlogDetailsRightSidebarPage",
  //   component: BlogDetailsRightSidebarPage,
  // },
  // {
  //   path: "/blog-details",
  //   name: "BlogDetailsPage",
  //   component: BlogDetailsPage,
  // },
  // {
  //   path: "/login",
  //   name: "LoginPage",
  //   component: LoginPage,
  // },
  // {
  //   path: "/register",
  //   name: "RegisterPage",
  //   component: RegisterPage,
  // },
  {
    path: "/tos",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;