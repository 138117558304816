import { createI18n } from 'vue-i18n';
import fr from './fr';
import en from './en';

const messages = {
  en,
  fr
};

const supportedLanguages = ['en', 'fr'];
const userLanguage = (navigator.language || navigator.language).split('-')[0];
const defaultLanguage = 'en';

const lang = createI18n({
  locale: supportedLanguages.includes(userLanguage) ? userLanguage : defaultLanguage,
  fallbackLocale: 'en',
  messages,
});

export default lang;