<template>
  <div class="terms-wrap ptb-100">
    <div class="container">
      <div class="single-para">
        <ul>
          <li>
            <p>{{ $t('terms.scopeOfConditions.title') }}</p>
            <ul>
              <li>
                <p>{{ $t('terms.scopeOfConditions.content1') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.scopeOfConditions.content2') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.scopeOfConditions.content3') }}</p>
              </li>
            </ul>
          </li>
          <br>
          <li>
            <p>{{ $t('terms.intellectualProperty.title') }}</p>
            <ul>
              <li>
                <p>{{ $t('terms.intellectualProperty.content1') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.intellectualProperty.content2') }}</p>
              </li>
            </ul>
          </li>
          <br>
          <li>
            <p>{{ $t('terms.privacyAndDataSecurity.title') }}</p>
            <ul>
              <li>
                <p>{{ $t('terms.privacyAndDataSecurity.content1') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.privacyAndDataSecurity.content2') }}</p>
              </li>
            </ul>
          </li>
          <br>
          <li>
            <p>{{ $t('terms.limitationOfLiability.title') }}</p>
            <ul>
              <li>
                <p>{{ $t('terms.limitationOfLiability.content1') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.limitationOfLiability.content2') }}</p>
              </li>
            </ul>
          </li>
          <br>
          <li>
            <p>{{ $t('terms.abusiveContent.title') }}</p>
            <ul>
              <li>
                <p>{{ $t('terms.abusiveContent.content1') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.abusiveContent.content2') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.abusiveContent.content3') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.abusiveContent.content4') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.abusiveContent.content5') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.abusiveContent.content6') }}</p>
              </li>
            </ul>
          </li>
          <br>
          <li>
            <p>{{ $t('terms.conflictResolution.title') }}</p>
            <ul>
              <li>
                <p>{{ $t('terms.conflictResolution.content1') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.conflictResolution.content2') }}</p>
              </li>
            </ul>
          </li>
          <br>
          <li>
            <p>{{ $t('terms.updatesCgu.title') }}</p>
            <ul>
              <li>
                <p>{{ $t('terms.updatesCgu.content1') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.updatesCgu.content2') }}</p>
              </li>
            </ul>
          </li>
          <br>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
};
</script>