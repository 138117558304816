import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5094626c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb-wrap index-1" }
const _hoisted_2 = { class: "container text-center index-1" }
const _hoisted_3 = { class: "br-title fs-52 mb-15" }
const _hoisted_4 = { class: "br-menu list-style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.pageTitle), 1),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('home.title')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, _toDisplayString(_ctx.mainTitle), 1)
      ])
    ])
  ]))
}