<template>
  <div>
    <NavbarTwo class="home_five" />
    <PageBanner :pageTitle="$t(`privacyPolicy.title`)" :mainTitle="$t(`privacyPolicy.title`)" />
    <PrivacyPolicy />
    <DownloadTheApp />
    <MainFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavbarTwo from "../components/Layouts/NavbarTwo.vue";
import PageBanner from "../components/Common/PageBanner.vue";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy.vue";
import DownloadTheApp from "../components/Common/DownloadTheApp.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "PrivacyPolicyPage",
  components: {
    NavbarTwo,
    PageBanner,
    PrivacyPolicy,
    DownloadTheApp,
    MainFooter,
  },
});
</script>