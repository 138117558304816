const fr = {
  appName: 'Act',
  navBar: {
    vision: 'Vision',
  },
  home: {
    title: 'Accueil',
    subtitle: `Votre Application d'Actualités Libres et Impartiales`,
    aboutUs1: "ACT redéfinit le paysage médiatique avec une audace qui déroute, une application où l'actualité n'est plus un monologue mais un dialogue vibrant. Imaginez un monde où chaque information est le point de départ d'une exploration collective, où chaque lecteur devient un acteur clé dans la quête de vérité. Chez ACT, nous ne nous contentons pas de changer les règles du jeu, nous inventons un nouveau jeu où l'impartialité et l'engagement citoyen redessinent les contours de l'information.",
    aboutUs2: "Notre mission est claire. Bâtir un écosystème d'information régi par l'intégrité, l'authenticité, et une quête incessante de diversité dans les perspectives. Nous offrons un média social où la qualité de l'information prime, où chaque voix peut résonner avec force et clarté, façonnant ainsi le paysage médiatique de demain.",
    aboutUs3: "Le processus est simple mais puissant : chaque Act publié initie une vague de réactions. Les utilisateurs, armés de leur discernement et de leur expertise, contribuent par des Reacts (réactions) qui ne se contentent pas de réagir, mais qui évaluent, analysent et enrichissent le débat. Ce cycle d'Acts et de Reacts crée une dynamique vivante et constamment évolutive.",
  },
  privacyPolicy: {
    title: 'Politique de confidentialité',
    description: `La présente Politique de Confidentialité régit la collecte, l'utilisation et la protection des données des utilisateurs de l'application Act ("l'Application") développée par SAVEPNP ("nous", "notre", "nos", "l'entreprise"). Nous attachons une grande importance à la confidentialité des utilisateurs de notre application et nous nous engageons à protéger leurs données conformément aux lois et régulations applicables en matière de confidentialité des données.`,
    collectData: {
      title: `Collecte de données`,
      content: `Nous pouvons collecter certaines informations personnelles identifiables (PII) auprès des utilisateurs, y compris mais sans s'y limiter : nom, adresse e-mail, numéro de téléphone, adresse postale et autres informations similaires, lorsque ces informations sont volontairement soumises par les utilisateurs dans le cadre de l'utilisation de l'Application. Nous pouvons également collecter automatiquement certaines informations non personnelles, telles que des données d'utilisation, des informations de connexion, des données de géolocalisation et d'autres informations similaires, lorsque les utilisateurs interagissent avec l'Application.`
    },
    useData: {
      title: `Utilisation des données`,
      content: `Nous utilisons les données collectées pour fournir, maintenir, améliorer et personnaliser l'expérience de l'utilisateur avec l'Application. Les données peuvent également être utilisées pour communiquer avec les utilisateurs, répondre à leurs demandes, fournir un service clientèle, envoyer des informations sur les mises à jour de l'Application, et à d'autres fins légitimes liées à l'exploitation de l'Application.`
    },
    protectData: {
      title: `Protection des données`,
      content: `Nous mettons en place des mesures de sécurité appropriées pour protéger les données des utilisateurs contre tout accès non autorisé, toute divulgation, toute altération ou toute destruction non autorisée des données. Cependant, il convient de noter qu'aucune méthode de transmission de données sur Internet, ni aucune méthode de stockage électronique, n'est totalement sécurisée. Nous ne pouvons donc pas garantir la sécurité absolue des données transmises ou stockées via l'Application.`
    },
    shareData: {
      title: `Partage des données`,
      content: `Nous ne vendons, n'échangeons, ni ne louons les données des utilisateurs à des tiers, sauf dans les cas prévus par la loi ou avec le consentement explicite des utilisateurs. Nous pouvons partager les données des utilisateurs avec des prestataires de services tiers qui nous aident à exploiter et à améliorer l'Application, à condition qu'ils respectent également la confidentialité des données.`
    },
    cookie: {
      title: `Cookies et technologies similaires`,
      content: `L'Application peut utiliser des cookies, des balises web, des pixels invisibles et d'autres technologies similaires pour collecter automatiquement certaines informations non personnelles, telles que des données d'utilisation, pour améliorer l'expérience de l'utilisateur, personnaliser le contenu et les publicités, et à d'autres fins légitimes.`
    },
    links: {
      title: `Liens vers des sites tiers`,
      content: `L'Application peut contenir des liens vers des sites web de tiers. Nous n'avons aucun contrôle sur le contenu ou les pratiques de confidentialité de ces sites et nous ne sommes pas responsables de leurs pratiques de confidentialité. Nous encourageons les utilisateurs à consulter les politiques de confidentialité de ces sites web tiers avant de soumettre des informations personnelles.`
    },
    majPrivacyPolicy: {
      title: `Mises à jour de la Politique de Confidentialité`,
      content: `Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment en publiant une version mise à jour sur ce lien https://goact.ai/privacy-policy. Les modifications prendront effet dès leur publication sur l'Application. Il est de la responsabilité des utilisateurs de consulter régulièrement cette Politique de Confidentialité pour être informés des éventuelles modifications. L'utilisation continue de l'Application après la publication des modifications constitue une acceptation de ces modifications.`
    },
    consent: {
      title: `Consentement`,
      content: `En utilisant l'Application, les utilisateurs consentent à la collecte, à l'utilisation et à la protection de leurs données conformément à cette Politique de Confidentialité.`
    },
    rightUser: {
      title: `Droit des utilisateurs`,
      content: `Les utilisateurs ont le droit d'accéder à leurs données personnelles, de les rectifier, de les supprimer, de s'opposer à leur traitement ou de demander leur portabilité, conformément aux lois et régulations applicables en matière de confidentialité des données. Les utilisateurs peuvent exercer ces droits en nous contactant par mail.`
    },
    contact: {
      title: `Contact`,
      content: `Si vous avez des questions, des préoccupations ou des commentaires concernant cette Politique de Confidentialité ou nos pratiques en matière de confidentialité des données, veuillez nous contacter.`
    },
    conclusion: {
      title: `Conclusion`,
      content: `Nous attachons une grande importance à la protection de la vie privée des utilisateurs de notre application. Nous nous engageons à traiter les données des utilisateurs de manière confidentielle et à prendre les mesures appropriées pour assurer leur sécurité. Nous vous encourageons à consulter régulièrement cette Politique de Confidentialité pour rester informé des pratiques de confidentialité de notre application.`
    },
    startDate: `Date d'entrée en vigueur : 24/04/2023`,
  },
  terms: {
    title: `Conditions générales d'utilisation`,
    scopeOfConditions: {
      title: `Portée et conditions d'utilisation`,
      content1: `L'utilisation de cette application est soumise à l'acceptation inconditionnelle des présentes Conditions Générales d'Utilisation.`,
      content2: `L'utilisateur doit respecter les lois et régulations applicables en utilisant l'application.`,
      content3: `L'utilisateur ne peut pas utiliser l'application à des fins illégales, frauduleuses, nuisibles ou offensantes.`
    },
    intellectualProperty: {
      title: `Propriété intellectuelle`,
      content1: `Tous les droits de propriété intellectuelle liés à l'application, y compris les droits d'auteur, les marques de commerce, les brevets, et les secrets commerciaux, sont la propriété de SAVEPNP.`,
      content2: `L'utilisateur n'est pas autorisé à copier, reproduire, distribuer, modifier, ou créer des œuvres dérivées de l'application sans l'autorisation préalable écrite de SAVEPNP.`,
    },
    privacyAndDataSecurity: {
      title: `Confidentialité et sécurité des données`,
      content1: `SAVEPNP collecte, utilise et protège les données des utilisateurs conformément à sa politique de confidentialité disponible sur l'application.`,
      content2: `SAVEPNP met en place des mesures de sécurité raisonnables pour protéger les données des utilisateurs, mais ne peut garantir la sécurité absolue des données transmises via l'application.`,
    },
    limitationOfLiability: {
      title: `Limitation de responsabilité`,
      content1: `L'utilisation de l'application se fait aux risques et périls de l'utilisateur. SAVEPNP ne peut être tenu responsable des pertes, dommages, litiges ou préjudices découlant de l'utilisation de l'application.`,
      content2: `SAVEPNP ne garantit pas que l'application sera exempte d'erreurs, d'interruptions, de virus ou d'autres composants nuisibles.`,
    },
    abusiveContent: {
      title: `Tolérance zéro pour tout contenu répréhensible et les utilisateurs abusifs`,
      content1: `SAVEPNP a une politique de tolérance zéro envers le contenu répréhensible et les utilisateurs abusifs.`,
      content2: `Le contenu répréhensible comprend, mais n'est pas limité à : discours de haine, menaces, harcèlement, pornographie infantile, incitation à la violence, etc.`,
      content3: `Les utilisateurs doivent s'abstenir de tout comportement abusif envers d'autres utilisateurs de l'application.`,
      content4: `SAVEPNP se réserve le droit de prendre des mesures, telles que la suppression de contenu, la désactivation de comptes, ou la transmission d'informations aux autorités compétentes, pour traiter les cas de contenu répréhensible ou d'utilisateurs abusifs.`,
      content5: `SAVEPNP s'engage à traiter tous les contenus signalés dans un délai maximal de 24 heures à compter de la réception du signalement.`,
      content6: `Les utilisateurs sont encouragés à signaler tout contenu répréhensible ou utilisateur abusif afin que SAVEPNP puisse prendre les mesures appropriées dans les plus brefs délais.`,
    },
    conflictResolution: {
      title: `Procédures de résolution des conflits`,
      content1: `Tout litige découlant de l'utilisation de l'application sera soumis à une juridiction exclusive des tribunaux compétents.`,
      content2: `Les parties s'engagent à tenter de résoudre tout litige de manière amiable avant de recourir à des procédures judiciaires.`,
    },
    updatesCgu: {
      title: `Mises à jour des CGU`,
      content1: `SAVEPNP se réserve le droit de mettre à jour les présentes CGU à tout moment en publiant une version mise à jour sur ce lien https://goact.ai/tos.`,
      content2: `Les utilisateurs seront informés des mises à jour des CGU et devront les accepter pour continuer à utiliser l'application.`,
    },
  },
  downloadApp: {
    title: `Rejoignez le Mouvement !`,
    subtitle: `Téléchargez l'App pour Plonger au Cœur d'une Actualité Vraie et Impartiale`,
  },
  footer: {
    followUs: 'Plongez dans notre monde ! Suivez-nous sur les réseaux sociaux pour un contenu qui inspire et divertit !',
    contactUs: 'Contactez-nous',
    byAct: 'Par Act',
  }
};

export default fr;
